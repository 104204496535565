import React, { useEffect } from 'react'
import { createRoot } from 'react-dom/client'

import Modal from '@sharedComponents/React/ShadowedModal'
import PplButton from '../Button/EmployerButton'

import { LocalStorage } from '@components/Storage'
import { Logo, ModalButtons, ModalContent, ModalDescription } from './NotificationModal.Styled'

interface NotificationModalProps {
  description: string,
  functions: {
    accept: () => void,
    close?: (value?: boolean) => void,
    deny?: () => void,
  }
  image?: {
    url: string,
    title: string,
  }
  unmount(): void,
}

const NotificationModal = (props: NotificationModalProps) => {
	const storageListenerHandler = () => {
		if (LocalStorage.Get('notificationClosed')) {
			props.unmount()
		}
	}

	useEffect(() => {
		window.addEventListener('storage', storageListenerHandler)
		return () => {
			window.removeEventListener('storage', storageListenerHandler)
		}
	}, [])

	const closeFn = (fromIcon = true) => {
		if (fromIcon) {
			LocalStorage.Set('notificationClosed', 'true')
		}
		props.unmount()
		if (props.functions?.close && fromIcon) {
			props.functions.close()
		}
	}
	const acceptFn = () => {
		LocalStorage.Set('notificationClosed', 'true')
		closeFn(false)
		if (props.functions?.accept) {
			props.functions.accept()
		}
	}
	const denyFn = () => {
		LocalStorage.Set('notificationClosed', 'true')
		closeFn(false)
		if (props.functions?.deny) {
			props.functions.deny()
		}
	}

	return (
		<Modal
			show
			onClose={ () => closeFn(true) }
			notFullScreen
			clear
		>
			<ModalContent>
				{ props.image && (
					<Logo src={ props.image.url } alt={ props.image.title }/>
				) }
				<ModalDescription>{ props.description }</ModalDescription>
				<ModalButtons>
					<PplButton
						description="Nie"
						type="button"
						showDescriptionOnMobile
						clickHandler={ denyFn }
					/>
					<PplButton
						description="Tak"
						type="button"
						invertedColors
						showDescriptionOnMobile
						clickHandler={ acceptFn }
					/>
				</ModalButtons>
			</ModalContent>
		</Modal>
	)
}

export default NotificationModal

export const renderNotificationModal = (element: HTMLElement, props: Omit<NotificationModalProps, 'unmount'>) => {
	const root = createRoot(element)

	root.render(<NotificationModal { ...props } unmount={ () => root.unmount() }/>)
}
