import { css } from '@emotion/css'

import Style from '@emotion/styled'

import LinearProgress, {
	linearProgressClasses,
} from '@mui/material/LinearProgress'
import { LookingForJob } from '@pages/employee/Profile/interfaces'
import { Alpha, Blue, Gray, Green, Red, White, Yellow } from '@styles/Color'
import MediaQuery from '@styles/MediaQueries'

export const CreatorHeader = Style('div')({
	color: Gray[700],
})

export const Logo = Style('img')({
	display: 'inline-block',
	verticalAlign: 'middle',

	[MediaQuery.Medium]: {
		width: 85,
		margin: '5px 25px 5px 10px',
	},
})

export const SectionTitle = Style('div')({
	fontWeight: 700,
	fontSize: 16,
	marginBottom: 15,
})

export const SectionBlock = Style('div')({
	marginBottom: 20,
})

export const ProgressContainer = Style('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
})

export const ProgressBlock = Style('div')({
	margin: '0 -15px',
	maxWidth: 'unset',
})

export const Info = Style('div')({
	backgroundColor: Blue[50],
	color: Blue[800],
	display: 'grid',
	gridTemplateColumns: 'auto 1fr',
	fontSize: 12,
	marginBottom: 20,
	padding: '17px 12px',

	'& span': {
		fontSize: 16,
		lineHeight: '17px !important',
		margin: '0 10px',
		verticalAlign: 'text-top',
	},

	'& div': {
		display: 'inline-block',
	},
})

export const Stage1Image = Style('img')({
	borderRadius: 5,
	maxWidth: '100%',
	margin: '0 -5px',
	marginBottom: 25,

	[MediaQuery.Medium]: {
		borderRadius: 15,
		left: '50%',
		maxWidth: 'unset',
		position: 'relative',
		transform: 'translateX(-50%)',
		width: 705,
	},
})

export const StageLinearProgress = Style(LinearProgress)({
	height: 4,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: Gray[300],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: Blue[800],
	},
})

export const lookingForJobSelect = (type: LookingForJob) =>
	css({
		backgroundColor:
			type === 'yes'
				? Alpha(Green[100], 0.2)
				: type === 'maybe'
				  ? Yellow[100]
				  : Gray[100],
	})

export const radiusInput = css({
	'.MuiFormHelperText-root': {
		marginTop: 0,
		marginBottom: 6,
	},

	'& .MuiOutlinedInput-root': {
		display: 'grid',
		gridTemplateColumns: 'auto 30px 1fr',
	},

	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		WebkitAppearance: 'none',
		margin: 0,
	},

	'& input[type=number]': {
		MozAppearance: 'textfield',
	},
})

export const numericInput = css({
	'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
		WebkitAppearance: 'none',
		margin: 0,
	},

	'& input[type=number]': {
		MozAppearance: 'textfield',
	},
})

export const AgencyDescription = Style('div')<{ enabled: boolean }>(
	({ enabled }) => ({
		backgroundColor: Alpha(enabled ? Blue[800] : Red[800], 0.2),
		borderRadius: 5,
		display: 'grid',
		fontSize: 13,
		padding: '10px 15px',
	}),
)

export const UploadFileButton = Style('button')({
	border: '1px dashed ' + Blue[300],
	borderRadius: 10,
	color: Blue[800],
	padding: '25px 0',
	textAlign: 'center',
	width: '100%',
})

export const fileUploadIcon = css({
	fontSize: 24,
	lineHeight: '14px !important',
	marginRight: 15,
	verticalAlign: 'text-top',
})

export const fileUploadedIcon = css({
	fontSize: 16,
	lineHeight: '14px !important',
	marginRight: 15,
	verticalAlign: 'text-top',
})

export const fileRemoveIcon = css({
	fontSize: 18,
})

export const UploadedCvBlock = Style('div')({
	alignItems: 'center',
	border: '1px dashed ' + Blue[300],
	borderRadius: 10,
	color: Blue[800],
	display: 'grid',
	gridTemplateColumns: 'auto 1fr auto',
	overflow: 'hidden',
	padding: '19px 15px',
	textAlign: 'left',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	width: '100%',
})

export const FileName = Style('p')({
	margin: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
})

export const MyFilesTitle = Style('div')({
	alignItems: 'center',
	display: 'grid',
	gridTemplateColumns: '1fr auto',
})

export const MyFilesBlock = Style('div')<{ visible: boolean }>(
	({ visible }) => ({
		backgroundColor: White,
		bottom: visible ? 0 : '-200%',
		display: visible ? 'unset' : 'none',
		height: '90%',
		opacity: visible ? 1 : 0,
		position: 'absolute',
		transition: 'bottom 300ms ease, opacity 1ms ease',
		zIndex: 99999999,
		left: ' 50%',
		transform: 'translateX(-50%)',
		padding: 5,
		width: '100%',
	}),
)

export const MyFileButton = Style('button')<{ selected: boolean }>(
	({ selected }) => ({
		backgroundColor: selected ? Alpha(Blue[800], 0.3) : 'transparent',
		border: '1px solid ' + (selected ? Blue[800] : Blue[300]),
		borderRadius: 10,
		color: Blue[800],
		display: 'grid',
		gap: 10,
		gridTemplateColumns: 'auto 1fr 50px',
		gridTemplateRows: '1fr 1fr',
		justifyItems: 'start',
		marginBottom: 10,
		overflow: 'hidden',
		padding: 10,
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: '100%',
	}),
)

export const myFileClipIcon = css({
	alignSelf: 'center',
	color: Blue[800],
	fontSize: 17,
	gridColumn: 1,
	gridRow: 1,
})

export const MyFileName = Style('div')({
	gridColumn: 2,
	gridRow: 1,
	maxWidth: '100%',
	overflow: 'hidden',
	textAlign: 'left',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
})

export const MyFileDate = Style('div')({
	color: Gray[600],
	gridColumn: 2,
	gridRow: 2,
})

export const myFileSelectedIcon = css({
	alignSelf: 'center',
	gridColumn: 3,
	gridRow: '1/span 2',
})

export const selectFilesButton = css({
	fontWeight: 500,
	textTransform: 'none',
})

export const popupWidth = css({
	[MediaQuery.Medium]: {
		maxWidth: 470,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
})

export const PulsingIcon = Style('div')({
	color: Blue[800],
	flexDirection: 'column',
	display: 'grid',
	fontSize: 4,
	gap: 1,
	gridTemplateColumns: '1fr 1fr 1fr',
	width: 20,
})

export const ParsingStatus = Style('div')({
	alignItems: 'center',
	backgroundColor: Alpha(Blue[800], 0.05),
	display: 'grid',
	gridTemplateColumns: '1fr auto auto 1fr',
	gap: 6,
	lineHeight: '14px',
	width: '100%',
	padding: 15,

	'& div:first-child': {
		gridColumn: 2,
	},
	'& div:nth-child(2)': {
		color: Blue[800],
		fontSize: 11,
		gridColumn: 3,
	},
})

export const ParsingStatusBlock = Style('div')({
	bottom: 77,
	left: '45%',
	position: 'absolute',
	transform: 'translateX(-45%)',
	width: 'calc(100% + 3vw);',

	[MediaQuery.Large]: {
		transform: 'translateX(-50%)',
		left: '50%',
		width: 800,
	},
})

export const Progress = Style('div')<{ value: number }>(({ value }) => ({
	backgroundColor: Gray[300],
	border: '5px solid ' + Gray[300],
	borderRadius: 10,
	height: 25,
	position: 'relative',
	width: 250,

	div: {
		backgroundColor: Blue[800],
		borderRadius: 10,
		height: 17,
		position: 'absolute',
		top: -1,
		transition: '.2s',
		width: `${value}%`,
	},

	[MediaQuery.Medium]: {
		width: '100%',
	},
}))

export const LinkButton = Style('button')({
	color: Blue[800],
})

export const dangerIcon = css({
	color: Red[500],
	fontSize: 60,
})

export const successIcon = css({
	color: Blue[800],
	fontSize: 60,
})

export const EditBlock = Style('div')<{ visible: boolean }>(({ visible }) => ({
	backgroundColor: White,
	bottom: visible ? 0 : '-200%',
	display: visible ? 'unset' : 'none',
	height: '90%',
	opacity: visible ? 1 : 0,
	position: 'absolute',
	transition: 'bottom 300ms ease, opacity 1ms ease',
	zIndex: 99999999,
	left: ' 50%',
	transform: 'translateX(-50%)',
	padding: 5,
	width: '100%',
}))

export const ButtonsBlock = Style('div')({
	display: 'grid',
	gridTemplateColumns: '1fr 1fr',
	gap: 10,
})

export const HintsWrapper = Style('div')({
	marginBottom: 20,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
})

export const HintsBlock = Style('div')({
	alignItems: 'center',
	display: 'flex',
	gap: 20,
	justifyContent: 'space-between',
	margin: 10,
})

export const HintItem = Style('div')({
	alignItems: 'center',
	display: 'grid',
	gap: 5,
	gridTemplateColumns: '1fr',
	gridTemplateRows: '1fr auto',
	justifyItems: 'center',
})

export const Hint = Style('div')({
	alignItems: 'center',
	backgroundColor: Blue[50],
	borderRadius: 15,
	display: 'grid',
	maxWidth: 225,
	padding: '20px 10px',
	textAlign: 'center',
})

export const hintIcon = css({
	color: Gray[300],
	fontSize: 5,
	margin: '0 10px',
})
export const activeHintIcon = css({
	color: Blue[800],
	fontSize: 5,
	margin: '0 10px',
})
