import React, { useRef } from 'react'
import { createRoot } from 'react-dom/client'

import { Icon } from '@sharedComponents/React'
import { Black } from '@styles/Color'

import {
	Backdrop,
	CloseButton,
	closeButtonIcon,
	Container,
	Content,
	FooterContent,
	HeaderContent,
	ModalFooter,
	ModalHeader,
} from './Modal.Styled'

interface ModalProps {
	backdropColor?: boolean
	children?: React.ReactNode
	className?: string
	clear?: boolean
	closeButton?: boolean
	closeColor?: string
	disableBackdropClick?: boolean
	disableBackground?: boolean
	footer?: React.ReactNode
	fullScreenMedium?: boolean
	id?: string
	notFullScreen?: boolean
	header?: React.ReactNode
	onClose?: (clickEvent: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
	show?: boolean
	sizeClose?: number
	title?: React.ReactNode
	closeButtonNative?: boolean
}

const Modal = ({
	show,
	backdropColor,
	disableBackdropClick,
	notFullScreen,
	fullScreenMedium,
	clear,
	disableBackground,
	closeButtonNative,
	closeColor,
	children,
	footer,
	header,
	className,
	onClose,
}: ModalProps) => {
	if (!show) return null

	const backdropRef = useRef<HTMLDivElement>(null)

	const handleBackdropClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
	) => {
		if (event.target === backdropRef.current && onClose) {
			onClose(event)
		}
	}

	return (
		<Backdrop
			backdropColor={ backdropColor }
			onClick={ disableBackdropClick ? undefined : handleBackdropClick }
			ref={ backdropRef }
		>
			<Container
				className={ className ? className : '' }
				notFullScreen={ notFullScreen }
				fullScreenMedium={ fullScreenMedium }
				whiteBackground={ clear }
			>
				<ModalHeader
					shadowless={ clear || false }
					disableBackground={ disableBackground }
				>
					<HeaderContent>{ header || <></> }</HeaderContent>
					<CloseButton onClick={ event => onClose(event) }>
						{ closeButtonNative ? (
							'X'
						) : (
							<Icon
								className={ closeButtonIcon }
								style={{ color: closeColor || Black }}
								type='times'
								weight='light'
							/>
						) }
					</CloseButton>
				</ModalHeader>
				<Content>{ children }</Content>
				{ footer && (
					<ModalFooter>
						<FooterContent>{ footer }</FooterContent>
					</ModalFooter>
				) }
			</Container>
		</Backdrop>
	)
}

export const renderModal = (root: HTMLElement, props: ModalProps) => {
	createRoot(root).render(<Modal { ...props }>{ props.children }</Modal>)
}

export default Modal
