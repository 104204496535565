import React, { useState } from 'react'

import { cx } from '@emotion/css'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import {
	BasicType,
	BasicTypeWithType,
	LocationType,
} from '@pages/employee/Profile/interfaces'
import Fetch from '@sharedAssets/functions/fetch'

import {
	fieldWithRedAsterisk,
	formField,
} from '@pages/employee/Profile/Profile.Style'

interface ResponseWithType {
	id: number
	label?: string
	name?: string
	type: string
}

type InputType =
	| 'position'
	| 'skill'
	| 'licence'
	| 'studiesMajor'
	| 'language'
	| 'schoolName'
	| 'employer'
	| 'profession'
	| 'exclude_employer'
	| 'place'

interface Props {
	dataValue: BasicType | BasicTypeWithType
	required?: boolean
	error: string
	handleChange?: (value: BasicType | BasicTypeWithType) => void
	handleBlur?: (value: BasicType | BasicTypeWithType) => void
	label?: string
	defaultOptions?: BasicType[] | BasicTypeWithType[]
	disabled?: boolean
	type: InputType
	inputId?: string
	onlyDictionaryAnswers?: boolean
	hideSelected?: BasicType[] | BasicTypeWithType[]
	isLocationField?: boolean
	filterLocationType?: LocationType
	multiline?: boolean
}

const getRequestUrl = (type: InputType, inputValue: string) => {
	switch (type) {
	case 'exclude_employer':
		return `/api/autocomplete/autocompleteEmployer/${ inputValue }`
	case 'place':
		return `localization/autocomplete/${ inputValue }/?type=all`
	default:
		return `/profile/dictionary/${ type }/${ inputValue }`
	}
}

const AutocompleteField = ({
	dataValue,
	required,
	error,
	handleChange,
	handleBlur,
	type,
	label,
	disabled,
	defaultOptions = [],
	inputId,
	onlyDictionaryAnswers,
	hideSelected,
	filterLocationType,
	multiline = false,
}: Props) => {
	const [ selectedValue, setSelectedValue ] = useState<
		BasicType | BasicTypeWithType | null
	>(dataValue)
	const [ options, setOptions ] = useState<BasicType[] | BasicTypeWithType[]>(
		defaultOptions,
	)
	const [ loading, setLoading ] = useState<boolean>(false)
	const [ abortController, setAbortController ] =
		useState<AbortController | null>(null)

	const getDataOnRequest = async (
		inputValue: string,
		setIsLoading: (value: boolean) => void,
		cancelSignal: AbortSignal,
	) => {
		const url = getRequestUrl(type, inputValue)

		try {
			setIsLoading(true)
			const response = await Fetch('GET', url, null, false, cancelSignal)

			const { data } = (await response.json()) as {
				data: BasicType[] | ResponseWithType[]
			}
			let newOptions: BasicType[] = []

			if (
				data.length > 0 &&
				((data[0] as ResponseWithType).label ||
					(data[0] as ResponseWithType).name)
			) {
				const mappedOptions = data.map(option => ({
					value: option.id,
					title: option?.label ?? option?.name,
					type: option.type,
				}))

				newOptions =
					type === 'place' && filterLocationType
						? mappedOptions.filter(option => option.type === filterLocationType)
						: [...mappedOptions]
			} else {
				newOptions = data as BasicType[]
			}

			if (hideSelected && hideSelected.length > 0) {
				newOptions = newOptions.map(option =>
					hideSelected.includes(option as BasicTypeWithType) ? void 0 : option,
				)
			}

			setOptions(newOptions.length ? newOptions : defaultOptions)
		} catch (err) {
			setOptions(defaultOptions)
		} finally {
			setIsLoading(false)
		}
	}

	const handleChangeSelectedValue = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: BasicType | BasicTypeWithType | null,
	) => {
		if (typeof value === 'object') {
			setSelectedValue(value)
			handleChange(value)
		}
	}

	const handleChangeCurrentValue = (
		event: React.ChangeEvent<HTMLInputElement>,
		value: string,
	) => {
		if (value === 'undefined') {
			return
		}

		if (type !== 'place') {
			handleChange({ value: null, title: value })
		}

		if (abortController) {
			abortController.abort()
		}

		if (value.trim().length < (type === 'place' ? 3 : 2)) {
			setOptions(defaultOptions)
			return
		}

		const newController = new AbortController()

		setAbortController(newController)
		getDataOnRequest(value, setLoading, newController.signal)
	}

	const getOptionLabel = (option: BasicType | string) => {
		if (typeof option === 'object') {
			return option.title
		} else {
			return option
		}
	}

	const isOptionEqualToValue = (
		option: BasicType | BasicTypeWithType | string,
		value: BasicType | BasicTypeWithType | string,
	) => {
		if (typeof option === 'object' && typeof value === 'object') {
			return option.title === value.title
		} else {
			return false
		}
	}

	return (
		<Autocomplete
			disabled={ !!disabled }
			value={ selectedValue }
			onChange={ handleChangeSelectedValue }
			onBlur={ () => (handleBlur ? handleBlur(selectedValue) : void 0) }
			onInputChange={ handleChangeCurrentValue }
			freeSolo={ !onlyDictionaryAnswers }
			noOptionsText='Brak opcji'
			loading={ loading }
			loadingText='Ładowanie...'
			options={ options }
			getOptionLabel={ getOptionLabel }
			filterOptions={ options => options }
			isOptionEqualToValue={ isOptionEqualToValue }
			renderInput={ params => (
				<TextField
					{ ...params }
					multiline={ multiline }
					label={ label || '' }
					error={ !!error }
					helperText={ error || ' ' }
					className={ cx(formField, fieldWithRedAsterisk) }
					required={ required }
					id={ inputId }
				/>
			) }
		/>
	)
}

export default AutocompleteField
