import Styled from '@emotion/styled'
import { css } from '@emotion/css'

import { Alpha, Black, Gray, White } from '@styles/Color'
import {
  AlignSelf,
  DisplayGrid, GridColumn, GridRow,
  GridTemplateColumns,
  GridTemplateRows,
  JustifySelf
} from '@styles/Grid'
import { MediaQuery } from '@styles/MediaQueries'
/*
 * Components
 */

export const Backdrop = Styled('div')<{ backdropColor?: boolean }>(({ backdropColor }) => ({
  alignContent: 'center',
  background: Alpha(Black, 0.2),
  bottom: 0,
  ...DisplayGrid(),
  ...GridTemplateColumns('1fr'),
  ...GridTemplateRows('1fr'),
  left: 0,
  overflow: 'hidden',
  position: 'fixed',
  right: 0,
  top: 0,
  zIndex: 999,
}))

interface ContainerProps {
  notFullScreen?: boolean,
  bgColor?: string,
  fullScreenMedium?: boolean,
  whiteBackground?: boolean,
}

export const Container = Styled('div')<ContainerProps>(({ notFullScreen, fullScreenMedium, whiteBackground }) => ({
  ...AlignSelf('center'),
  background: whiteBackground ? White : Gray[100],
  boxShadow: '0 0 21px 0 #9E9E9E',
  boxSizing: 'border-box',
  ...DisplayGrid(),
  ...GridTemplateRows('auto 1fr auto'),
  ...GridTemplateColumns('1fr'),
  height: notFullScreen ? 'auto' : '100vh',
  ...JustifySelf('center'),
  maxHeight: notFullScreen ? 'calc(100vh - 30px)' : null,
  maxWidth: notFullScreen ? 'calc(100vw - 30px)' : null,
  minWidth: notFullScreen ? 290 : null,
  overflow: 'auto',
  position:  notFullScreen ? 'relative' : 'fixed' ,
  width: notFullScreen ? [ 'auto', 'fit-content' ] : '100vw',

  [MediaQuery.Medium]: {
    height: fullScreenMedium ? '100vh' : [ 'auto', 'fit-content' ],
    maxHeight: fullScreenMedium ? '100vh' : 'calc(100vh - 30px)',
    maxWidth: fullScreenMedium ? '100vw' : 'calc(100vw - 30px)',
    minWidth: 320,
    position:  fullScreenMedium ? 'fixed' : 'relative' ,
    width: fullScreenMedium ? '100vw' : [ 'auto', 'fit-content' ],
  },

  [MediaQuery.Large]: {
    height: [ 'auto', 'fit-content' ],
    maxHeight: 'calc(100vh - 30px)',
    maxWidth: 'calc(100vw - 30px)',
    position: 'relative',
    width:  [ 'auto', 'fit-content' ],
  },
}))

export const Content = Styled('div')({
  ...GridColumn(1),
  ...GridRow(2),
  overflow: 'auto',
  padding: 10,
})

export const ModalFooter = Styled('div')({
  ...AlignSelf('center', true),
  backgroundColor: White,
  boxShadow: '0 2px 10px 0 ' + Gray[500],
  ...GridColumn(1),
  ...GridRow(3),
  marginTop: 10,
  minHeight: 64,
  overflow: 'hidden',
  padding: 10,

  [MediaQuery.Large]: {
    minHeight: 'auto',
  },
})

export const FooterContent = Styled('div')({
  backgroundColor: White,
  bottom: 0,
  left: 0,
  position: 'fixed',
  textAlign: 'center',
  width: '100vw',

  [MediaQuery.Large]: {
    position: 'static',
    width: 'auto',
  },
})

export const ModalHeader = Styled('div')<{ disableBackground?: boolean, shadowless: boolean }>(({ disableBackground, shadowless }) => ({
  ...AlignSelf('center', true),
  backgroundColor: disableBackground ? 'transparent' : White,
  boxShadow: (shadowless || disableBackground) ? 'none' : '0 2px 10px 0 ' + Gray[500],
  ...DisplayGrid(),
  ...GridTemplateColumns('1fr auto'),
  ...GridTemplateRows('1fr'),
  ...GridColumn(1),
  ...GridRow(1),
  marginBottom: shadowless ? 0 : 10,
  padding: 10,
  paddingBottom: shadowless ? 0 : 10,
}))

export const HeaderContent = Styled('div')({
  ...AlignSelf('center', true),
  color: Gray[800],
  fontSize: 18,
  fontWeight: 600,
  ...GridColumn(1),
  ...GridRow(1),
  paddingRight: 20,
  textTransform: 'uppercase',

  [MediaQuery.Large]: {
    fontSize: 16,
  },
})

export const CloseButton = Styled('div')({
  cursor: 'pointer',
  ...GridColumn(2),
  ...GridRow(1),
})

export const closeButtonIcon = css({
  fontSize: 30,
})
