import React from 'react'
import { capitalize } from 'lodash'

import { css,cx } from '@emotion/css'
import { ButtonProps, IconButton } from '@mui/material'
import { Icon } from '@sharedComponents/React'
import { Blue, Red } from '@styles/Color'

type Props = ButtonProps & {
	icon: string
}

export const RoundedButton = ({
	icon,
	className,
	size = 'medium',
	color = 'primary',
	...restProps
}: Props) => {
	const roundedClasses = css({
		[`&.MuiIconButton-color${ capitalize(color) }`]: {
			backgroundColor: color === 'primary' ? Blue[50] : Red[50],
			color: color === 'primary' ? Blue[800] : Red[600],
			height: size === 'large' ? 45 : 40,
			width: size === 'large' ? 45 : 40,
			fontSize: size === 'large' ? 24 : 18,

			'&:hover': {
				backgroundColor: color === 'primary' ? Blue[100] : Red[100],
			},
		},
	})

	return (
		<IconButton
			aria-label='edytuj'
			color={ color }
			className={ cx(roundedClasses, className) }
			{ ...restProps }
		>
			<Icon type={ icon } weight='light' />
		</IconButton>
	)
}
