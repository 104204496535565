import Styled from '@emotion/styled'
import { css } from '@emotion/css'

import { Blue, Gray, Red, White } from '@styles/Color'
import MediaQuery from '@styles/MediaQueries'

interface ButtonRootProps {
	bold?: boolean
	borderless?: boolean
	danger?: boolean
	disabled?: boolean
	inverted?: boolean
	withMobileDescription?: boolean
	hideOnMobile?: boolean
}

export const ButtonRoot = Styled('button')<ButtonRootProps>`
  ${props => props.className};
  background-color: ${({ disabled, inverted, danger }) =>
		disabled ? Gray[400] : inverted ? (danger ? Red[500] : Blue[800]) : White};
  border: ${({ borderless }) =>
		`1px solid ${borderless ? 'transparent' : Blue[800]}`};
  border-radius: 25px;
  color: ${({ inverted, disabled, danger }) =>
		inverted || disabled ? White : danger ? Red[500] : Blue[800]};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
  font-size: ${({ bold }) => (bold ? '16px' : '14px')};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  min-width: 45px;
  outline: none !important;
  padding: ${({ withMobileDescription }) =>
		withMobileDescription ? '10px 27px' : '8px 10px'};
  text-align: center;
  width: 100%;

  ${MediaQuery.Medium} {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'block')};
  }

  ${MediaQuery.Large} {
    display: block;
    padding: 7px 17px;
  }

  &:hover {
    background-color: ${({ disabled, inverted, danger }) =>
			disabled
				? Gray[400]
				: inverted
				  ? danger
						? Red[600]
						: Blue[900]
				  : White};
  }
`

export const ButtonLinkRoot = Styled('a')<ButtonRootProps>`
  background-color: ${({ inverted, danger }) =>
		inverted ? (danger ? Red[500] : Blue[800]) : White};
  border: ${({ borderless, danger }) =>
		`1px solid ${borderless ? 'transparent' : danger ? Red[500] : Blue[800]}`};
  border-radius: 20px;
  color: ${({ inverted, danger }) =>
		inverted ? White : danger ? Red[500] : Blue[800]};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: ${props => (props.hideOnMobile ? 'none' : 'block')};
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  line-height: 20px;
  min-width: 45px;
  padding: ${({ withMobileDescription }) =>
		withMobileDescription ? '10px 27px' : '8px 10px'};
  text-align: center;
  width: 100%;

  &:hover {
    color: ${({ inverted, danger }) =>
			inverted ? White : danger ? Red[500] : Blue[800]};
  }

  &:active {
    color: ${White} ;
  }

  ${MediaQuery.Medium} {
    display: block;
  }

  ${MediaQuery.Large} {
    line-height: 15px;
    padding: 7px 17px;
  }
`

export const buttonIcon = (onlyIcon?: boolean) =>
	css({
		fontSize: 18,
		paddingRight: onlyIcon ? 0 : 10,

		[MediaQuery.Large]: {
			fontSize: 15,
      paddingRight: 10
		},
	})

export const ButtonDescription = Styled('span')<{ showOnMobile?: boolean }>(
	({ showOnMobile }) => ({
		display: showOnMobile ? 'inline' : 'none',
		fontSize: 15,
		fontWeight: 600,

		[MediaQuery.Large]: {
			display: 'inline',
			fontSize: 12,
		},
	}),
)

export const PrimaryDescription = Styled('span')<{ hideOnDesktop?: boolean }>(
	({ hideOnDesktop }) => ({
		[MediaQuery.Large]: {
			display: hideOnDesktop ? 'none' : 'inline',
		},
	}),
)

export const DesktopDescription = Styled('span')({
	display: 'none',

	[MediaQuery.Large]: {
		display: 'inline',
	},
})
