import { css } from '@emotion/css'

import Style from '@emotion/styled'

import { Gray } from '@styles/Color'
import MediaQuery from '@styles/MediaQueries'

export const ModalBody = Style('div')({
	display: 'grid',
	gridTemplateRows: 'auto 1fr auto',
	height: '100%',
	maxHeight: '100vh',

	[MediaQuery.Large]: {
		height: 768,
		maxHeight: '95vh',
		minHeight: '65vh',
		width: 768,
	},
})

export const ModalHeader = Style('div')<{ withMargin?: boolean }>(
	({ withMargin }) => ({
		alignItems: 'center',
		padding: withMargin ? '10px 15px 0px 10px' : '15px 15px',
		borderBottom: '1px solid ' + Gray[200],
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		margin: '0 -15px',
		width: 'calc(100% + 30px)',
	}),
)

export const HeaderContent = Style('div')<{ asText: boolean }>(
	({ asText }) => ({
		fontSize: 16,
		fontWeight: 600,
		overflow: 'auto',
		textTransform: asText ? 'none' : 'uppercase',
	}),
)

export const BelowHeader = Style('div')({
	gridColumn: '1/span 2',
	gridRow: 2,
	marginTop: 10,
})

export const ModalContent = Style('div')<{ withMargin?: boolean }>(
	({ withMargin }) => ({
		borderBottom: '1px solid ' + Gray[200],
		overflowX: 'hidden',
		overflowY: 'auto',
		padding: withMargin ? '10px 15px 15px 0px' : '5px 15px',
		[MediaQuery.Medium]: {
			justifyItems: 'center',

			'& > *': {
				margin: '0 auto',
				maxWidth: 468,
			},
		},
	}),
)

export const ModalFooter = Style('div')({
	alignItems: 'center',
	display: 'grid',
	gap: 8,
	gridAutoFlow: 'column',
	justifyItems: 'center',
	marginBottom: 0,
	maxWidth: 'calc(100vw - 30px)',
	padding: '15px 0px 10px 0px',
	position: 'relative',
	width: '100%',

	'& > button': {
		lineHeight: '38px',
		minWidth: 150,

		[MediaQuery.Medium]: {
			minWidth: 175,
		},
	},
})

export const spinner = css({
	'--fa-animation-duration': '1s',
})
