import React, { useEffect,useRef, useState } from 'react'

import { ThemeProvider } from '@mui/material/styles'
import { FooterButtons } from '@pages/employee/Profile/interfaces'
import { ProfileModal } from '@pages/employee/Profile/Partials/PopupModal'
import { mainTheme } from '@sharedComponents/React/pracaMuiTheme'

interface Props {
	children: React.ReactNode
	closeFn: () => void
	saveFn: () => Promise<void>
	header: React.ReactElement | string,
	removeFn?: () => Promise<void>
}

export const SectionEdit = ({ children, closeFn, saveFn, header, removeFn }: Props) => {
	const [ isSaving, setIsSaving ] = useState<boolean>(false)
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
		}
	}, [])

	const handleSave = async () => {
		if (isSaving) return

		setIsSaving(true)
		try {
			await saveFn()
		} finally {
			if (isMounted.current) {
				setIsSaving(false)
			}
		}
	}

	const getFooter = () => {
		const footer: FooterButtons = {
			rightButton: {
				fullWidth: !removeFn,
				text: 'Zapisz',
				action: handleSave,
				loading: isSaving,
				disabled: isSaving,
			},
			leftButton: {
				text: 'Usuń',
				action: removeFn,
				colorTheme: 'error',
			}
		}

		if (!removeFn) {
			delete footer.leftButton
		}

		return footer
	}

	const close = async () => {
		closeFn()
		return true
	}

	return (
		<ProfileModal
			closeFn={ close }
			closeTitle='Anuluj'
			header={ header }
			footer={ getFooter() }
		>
			<ThemeProvider theme={ mainTheme } >
				{ children }
			</ThemeProvider>
		</ProfileModal>
	)
}