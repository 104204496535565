import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'

import { Icon } from '@components/React'
import { cx } from '@emotion/css'
import { Button,FormHelperText, IconButton } from '@mui/material'
import { useEditSection } from '@pages/employee/Profile/hooks/useEditSection'
import { AgencyConsent, CvFile, UploadFileData } from '@pages/employee/Profile/interfaces'
import { uploadCv } from '@pages/employee/Profile/Partials/Creator/functions'
import { fileTypes, validateFile } from '@pages/employee/Profile/Partials/Creator/validations'
import RadioYesNo from '@pages/employee/Profile/Partials/RadioYesNo'

import { consentButton, ConsentText } from '@pages/employee/Profile/Partials/AgencyConsent/AgencyConsent.Style'
import {
	AgencyDescription,
	FileName,
	fileRemoveIcon,
	fileUploadedIcon,
	fileUploadIcon,
	Info, MyFileButton, myFileClipIcon,
	MyFileDate, MyFileName,
	MyFilesBlock,
	myFileSelectedIcon, MyFilesTitle, popupWidth,
	SectionBlock,
	SectionTitle, selectFilesButton,
	UploadedCvBlock,
	UploadFileButton,
} from '@pages/employee/Profile/Partials/Creator/Creator.Style'
import { CollapseButton, VisuallyHiddenInput } from '@pages/employee/Profile/Profile.Style'

export type Stage2Ref = {
	isFileLoading: () => boolean
	getFileId: () => Promise<number>
	getCurrentData: () => AgencyConsent
}

interface Stage2Props {
	updateForm: (value: AgencyConsent) => void
	loading: boolean
	agencyConsent: AgencyConsent
	showAgencyConsent: boolean
	files: Omit<CvFile, 'url'>[]
}

export declare type Stage2Field = 'accepted'
export declare type Stage2Types = boolean

const Stage2 = forwardRef(function Stage2(props: Stage2Props, ref) {
	const [ cvFile, setCvFile ] = useState<UploadFileData>()
	const [ fileErrors, setFileErrors ] = useState<string>('')
	const [ fileUploading, setFileUploading ] = useState<boolean>(false)
	const [ consentToggle, setConsentToggle ] = useState<boolean>(false)
	const [ myFilesVisible, setMyFilesVisibility ] = useState<boolean>(false)
	const [ fileData, setFileData ] = useState<File>(null)

	const { handleChange, currentValue } =
		useEditSection('agencyConsent', props.agencyConsent)

	const changeAgencyConsent = (value: boolean) => {
		handleChange(value, '[accepted]')
		props.updateForm({ ...currentValue, accepted: value })
	}

	const uploadCvRef = useRef<HTMLInputElement>()

	useImperativeHandle(ref, () => {
		return {
			isFileLoading(): boolean {
				return Boolean(fileData || cvFile)
			},
			async getFileId(): Promise<number> {
				return !fileData && !cvFile ?
					null : cvFile ?
						cvFile.id :
						handleFileSend().then((fileId) => fileId || null)
			},
			getCurrentData(): AgencyConsent {
				return currentValue
			}
		}
	})

	const handleFileUpload = async(file: File) => {
		const result = validateFile(file)
		if (result.success) {
			setFileData(file)
			setFileUploading(true)
		} else {
			setFileErrors(result.errors)
		}
	}

	const handleFileSend = async(): Promise<number> => {
		const uploadResult = await uploadCv(fileData)

		setFileUploading(false)
		if (uploadResult.status === 'success') {
			if (fileErrors) {
				setFileErrors('')
			}
			setCvFile(uploadResult.data)
			return uploadResult?.data?.id
		} else {
			setFileErrors(uploadResult.error.message)
			return null
		}
	}

	const renderFiles = (file: Omit<CvFile, 'url'>) =>
		<MyFileButton
			key={ 'file_' + file.id }
			selected={ cvFile?.id === file.id }
			onClick={ () => { setCvFile(file); setMyFilesVisibility(false) } }
		>
			<Icon type="paperclip" weight="light" className={ myFileClipIcon }/>
			<MyFileName title={ file.name }>{ file.name }</MyFileName>
			<MyFileDate>{ file.created }</MyFileDate>
			{ cvFile?.id === file.id && <Icon type="circle-check" weight="solid" className={ myFileSelectedIcon }/> }
		</MyFileButton>

	return (
		<div>
			{ props.showAgencyConsent && (<>
				<SectionTitle>Czy chcesz udostępniać swoje CV pracodawcom?</SectionTitle>
				<SectionBlock>
					<RadioYesNo
						value={ currentValue.accepted || false }
						onValueChange={ (value) => changeAgencyConsent(value) }
						optionNoRed
					/>
				</SectionBlock>
				<AgencyDescription enabled={ currentValue.accepted } style={{ marginBottom: 20 }}>
					<p>
						<strong>{ currentValue.accepted ? 'Wyrażam zgodę' : 'Nie wyrażam zgody' } na udostępnianie </strong>
						mojego CV pracodawcom
					</p>
					<div style={{ textAlign: 'end' }}>
						<CollapseButton
							className={ consentButton }
							onClick={ () => setConsentToggle(prev => !prev) }
							open={ consentToggle }
						>
							Pełna treść zgody <Icon type='chevron-down' />
						</CollapseButton>
					</div>
					<ConsentText expanded={ consentToggle }>
						Poprzez zaznaczenie tej opcji wyrażasz zgodę na udostępnienie Twoich
						danych osobowych znajdujących się w&nbsp;Twoim CV (w&nbsp;tym dane z&nbsp;profilu
						kandydata i&nbsp;formularza aplikacyjnego) przez Praca.pl pracodawcom,
						którzy poszukują pracowników w&nbsp;naszej bazie. W&nbsp;takim przypadku
						Praca.pl działa, jako agencja zatrudnienia, która uzyskane w&nbsp;ten
						sposób informacje przekazuje do dalszego procesu rekrutacji. Wyrażenie
						zgody jest dobrowolne, ale niezbędne do skorzystania z&nbsp;tej usługi. W&nbsp;każdej
						chwili możesz wycofać wyrażoną przez Ciebie zgodę. Poniżej w&nbsp;panelu
						kandydata „Moje CV” masz możliwość określenia pracodawców,
						którym nie chcesz udostępniać swojego CV. Podstawą przetwarzania
						danych osobowych jest w&nbsp;tym przypadku art. 6 ust. 1 lit. b i c RODO.
						Szczegółowe informacje znajdują się w&nbsp;
						<a href='/regulamin.html'>Regulaminie</a> oraz&nbsp;
						<a href='/polityka-prywatnosci.html'>Polityce Prywatności</a>.
					</ConsentText>
				</AgencyDescription>
			</>) }
			<SectionTitle>Czy masz plik CV?</SectionTitle>
			<SectionBlock>Automatycznie uzupełnimy Twój profil danym z Twojego CV, oszczędzając Twój czas i gwarantując zgodność.</SectionBlock>
			<SectionBlock>
				{ fileData || cvFile ? (
					<UploadedCvBlock>
						<Icon type="paperclip" weight="light" className={ fileUploadedIcon }/>
						<FileName title={ fileData?.name || cvFile?.name }>{ fileData?.name || cvFile?.name }</FileName>
						<IconButton aria-label="delete" onClick={ () => {
							setFileData(null)
							setCvFile(null)
							setFileUploading(false)
						} } color="error">
							<Icon type="trash-alt" weight="light" className={ fileRemoveIcon }/>
						</IconButton>
					</UploadedCvBlock>
				) : (
					<>
						<UploadFileButton onClick={ () => uploadCvRef.current.click() }>
							<Icon
								type={ fileUploading ? 'spinner-third' : 'circle-plus' }
								weight="light"
								className={ fileUploading ? cx(fileUploadIcon, 'fa-spin-pulse') : fileUploadIcon }
							/> { fileUploading ? 'Wczytywanie pliku...' : 'Załącz plik CV' }
							<VisuallyHiddenInput
								accept={ fileTypes.join(', ') }
								ref={ uploadCvRef }
								type="file"
								onChange={ ({ target }) => handleFileUpload(target.files[0]) }
							/>
						</UploadFileButton>
						<FormHelperText error={ Boolean(fileErrors) }>
							{ fileErrors ? fileErrors : 'Format: .doc, .docx, .odt, .pdf, .rtf, .txt. Rozmiar: max 15 MB' }
						</FormHelperText>
						{ props.files.length > 0 && (
							<div style={{ marginTop: 20 }}>
								<Button onClick={ () => setMyFilesVisibility(true) }>
									<Icon
										type="circle-plus"
										weight="light"
										className={ fileUploadIcon }
									/>
									<span className={ selectFilesButton }>Wybierz z &ldquo;Moje pliki&rdquo;({ props.files.length })</span>
								</Button>
								<MyFilesBlock visible={ myFilesVisible }>
									<div className={ popupWidth }>
										<SectionBlock>
											<MyFilesTitle>
												<span>Wybierz plik do przetworzenia</span>
												<IconButton aria-label="close" onClick={ () => setMyFilesVisibility(false) }>
													<Icon type="times" weight="light"/>
												</IconButton>
											</MyFilesTitle>
										</SectionBlock>
										<Info>
											<Icon type="info-circle" weight="light" />
											<div>Pliki pochodzą z zakładki „Moje pliki”. Lista uwzględnia tylko formaty: doc, docx, odt, pdf, rtf, .txt.</div>
										</Info>
										{ props.files.map((file) => renderFiles(file)) }
									</div>
								</MyFilesBlock>
							</div>
						) }
					</>
				) }
			</SectionBlock>
		</div>
	)
})
Stage2.displayName = 'Stage2'

export default Stage2
