import { css } from '@emotion/css'

import Style from '@emotion/styled'

import { Alpha, Black, Blue, Red } from '@styles/Color'
import MediaQuery from '@styles/MediaQueries'

export const Wrapper = Style('div')<{ checked: boolean }>(({ checked }) => ({
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: checked ? Blue[50] : Red[50],
	padding: '20px 10px',
	borderRadius: 10,
	transition: 'background-color 150ms ease-in-out',

	[MediaQuery.Xlarge]: {
		gridColumn: 2,
	}
}))

export const agencyConsentSwitch = (checked: boolean) => css({
	width: 'fit-content',
	alignItems: 'start !important',

	'& .MuiFormControlLabel-label': {
		paddingBlock: 10,
	},

	'& .MuiSwitch-track, & .MuiSwitch-thumb' : {
		background: (checked ? Blue[800] : Red[600]) + ' !important',
	},
})

export const ConsentText = Style('div')<{ expanded: boolean }>(({ expanded }) => ({
	display: expanded ? 'block' : 'none',
	paddingBlock: 20,
}))

export const consentButton = css({
	fontSize: 13,
	alignSelf: 'end',
})

export const excludeButton = css({
	alignSelf: 'start',
})

export const ExcludedEmployersBlock = Style('div')<{ show: boolean }>(({ show }) => ({
	marginBottom: 28,
	display: show ? 'block' : 'none',
}))

export const ExcludedEmployers = Style('div')<{ disabled: boolean }>(({ disabled }) => ({
	color: disabled ? Alpha(Black, 0.5) : Black,
}))
