import { cloneDeep, get, set } from 'lodash'

import { ProfileEditable, ProfileValidatable } from '../interfaces'

import { userProfileSchema } from './schema'

export function validateSection<T extends ProfileValidatable>(
	key: T,
	sectionData: ProfileEditable[T],
	onlySelectedField?: string[],
	disableValidationOptions?: {
		disableRadius?: boolean
		disableLocation?: boolean
	},
) {
	const currentSectionsData = cloneDeep(sectionData)
	const schemaPart = userProfileSchema(disableValidationOptions)[key]
	const validationState = {
		sectionValid: true,
		dataWithErrors: {},
	}

	if (onlySelectedField?.length > 0) {
		for (const part of schemaPart) {
			if (part[2].length > 0) {
				for (const subPart of part[2]) {
					if (onlySelectedField.includes(subPart[0])) {
						validatePath(part, currentSectionsData, validationState, null, [])
					}
				}
			}
			if (onlySelectedField.includes(part[0])) {
				validatePath(part, currentSectionsData, validationState, null, [])
			}
		}
	} else {
		for (const part of schemaPart) {
			validatePath(part, currentSectionsData, validationState, null, [])
		}
	}

	return validationState
}

const validatePath = (
	path,
	sectionData,
	validationState,
	customPath = null,
	currentPath = [''],
) => {
	const [ pathToValidate, validateItems, nestedValidations, validator ] = path

	if (pathToValidate || customPath !== null)
		currentPath.push(`[${ customPath ?? '' }]`, `[${ pathToValidate }]`)
	const completePath = currentPath.join('').replace('[]', '')

	if (validator) {
		let validationItem
		if (typeof sectionData === 'string' || typeof sectionData === 'number') {
			validationItem = sectionData
		} else {
			validationItem = get(sectionData, completePath)
		}

		const currentItem = get(sectionData, currentPath[0])
		const validationResult = validator(validationItem, currentItem)

		if (
			!completePath &&
			(typeof sectionData === 'string' || typeof sectionData === 'number')
		) {
			validationState.dataWithErrors = validationResult
		} else {
			set(validationState.dataWithErrors, completePath, validationResult)
			currentPath = []
		}

		if (validationResult) {
			validationState.sectionValid = false
		}
	}

	if (!nestedValidations) return

	for (const nestedPath of nestedValidations) {
		if (validateItems) {
			const objectToIterate = completePath
				? get(sectionData, completePath)
				: sectionData

			for (const [index] of objectToIterate.entries()) {
				validatePath(nestedPath, sectionData, validationState, index, [
					...currentPath,
				])
			}
		} else {
			validatePath(nestedPath, sectionData, validationState, null, [
				...currentPath,
			])
		}
	}
}
