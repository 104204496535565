import React, { useState } from 'react'

import { cx } from '@emotion/css'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

import { radio, radioActive, radioRoot } from '../../Profile.Style'

type Props = {
	disabled?: boolean
	onValueChange: (currentValue: boolean) => void
	value: boolean
	withoutDefault?: boolean
	optionNoRed?: boolean
}

type Options = 'yes' | 'no'

const validateProperValue = (value: string): value is Options =>
	[ 'yes', 'no' ].includes(value)

const RadioYesNo = ({ disabled, onValueChange, value, withoutDefault, optionNoRed }: Props) => {
	const [ selected, setSelected ] = useState<'yes' | 'no' | null>(
		withoutDefault && value === null ? null : value ? 'yes' : 'no',
	)

	const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target
		if (!validateProperValue(value)) return

		setSelected(value)
		onValueChange(value === 'yes')
	}

	return (
		<FormControl>
			<RadioGroup row onChange={ event => handleSelect(event) } value={ selected }>
				<FormControlLabel
					value={ 'yes' }
					control={ <Radio className={ radioRoot } /> }
					label='TAK'
					className={ cx(radio, selected === 'yes' && radioActive()) }
					disabled={ disabled }
				/>
				<FormControlLabel
					value={ 'no' }
					control={ <Radio className={ radioRoot } /> }
					label='NIE'
					className={ cx(radio, selected === 'no' && radioActive(optionNoRed)) }
					disabled={ disabled }
				/>
			</RadioGroup>
		</FormControl>
	)
}

export default RadioYesNo
