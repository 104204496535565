import { keyframes } from '@emotion/css'
import Emotion from '@emotion/styled'
import Color from '@styles/Color'

interface SpinnerProps {
  centered?: boolean,
  color?: string,
}

const rotate = keyframes({
	from: {
		transform: 'rotateZ(0)',
	},
	to: {
		transform: 'rotateZ(360deg)',
	},
})

const Spinner = Emotion('div')((props: SpinnerProps) => ({
	animation: `${ rotate } 1s linear infinite`,
	borderColor: `${ props.color || Color.Blue[800] } transparent`,
	borderRadius: '50%',
	borderStyle: 'solid',
	borderWidth: 4,
	height: 50,
	left: props.centered ? 'calc(50% - 25px)' : 'auto',
	margin: '20px auto',
	position: (props.centered ? 'absolute' : 'static') as any,
	top: props.centered ? 50 : 'auto',
	width: 50,
	zIndex: 101,
}))

export default Spinner
